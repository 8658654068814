<template>
    <div>

        <header id="header">
            <!--<div class="header-body border-top-0 bg-dark box-shadow-none">-->

            <!--PC header-->
            <div class="header_container open">
                <div class="inner">
                    <div class="logo">
                        <a href="#"><img :src="require('@/assets/img/main/tinteccnc_logo.svg')"/></a>
                    </div>
                    <div class="m_menu">
                        <a href="#"><img :src="require('@/assets/img/main/m_menu.svg')"/></a>
                    </div>
                    <div class="header_menu_wrap">
                        <ul>
                            <li>
                                <a href="#">ABOUT</a>
                                <div class="sub_menu menu01">
                                    <ul>
                                        <li>Profile</li>
                                        <li>M.P.S Business</li>
                                        <li>S.I Business</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <a href="#">PRODUCTS</a>
                                <div class="sub_menu menu02">
                                    <ul>
                                        <li>TN-BOX(WEB)</li>
                                        <li>TN-BOX(Mobile)</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <a href="#">PARTNERS</a>
                                <div class="sub_menu menu03">
                                    <ul>
                                        <li>Partners Program</li>
                                        <li>Becoming a Partner</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <a href="#">SUPPORT</a>
                                <div class="sub_menu menu04">
                                    <ul>
                                        <li>Manual Download</li>
                                        <li>Remote Download</li>

                                    </ul>
                                </div>
                            </li>
                            <li>
                                <a href="#">CONTACT US</a>
                                <div class="sub_menu menu05">
                                    <ul>
                                        <li>msg&Info</li>

                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <!--mobile header-->
            <div class="inner m_header">
                <div class="logo">
                    <a href="#"><img :src="require('@/assets/img/main/tinteccnc_logo.svg')"/></a>
                </div>
                <div>
                    <a href="#"><img :src="require('@/assets/img/main/m_menu.svg')"/></a>
                </div>


                <div class="m_header_submenu_wrap">
                    <div class="sub_menu_background"></div>
                    <div class="side_submenu">
                        <ul>
                            <div class="side_submenu_li_wrap">
                                <li class="font-weight-bold li_background"><a href="#">ABOUT</a></li>
                                <li><a href="#">Profile</a></li>
                                <li><a href="#">MPS Business</a></li>
                                <li><a href="#">SI Business</a></li>
                                <li class="font-weight-bold li_background"><a href="#">PRODUCT</a></li>
                                <li><a href="#">TN-BOX(Web)</a></li>
                                <li><a href="#">TN-BOX(Mobile)</a></li>
                                <li class="font-weight-bold li_background"><a href="#">PARTNERS</a></li>
                                <li><a href="#">Partners Programs</a></li>
                                <li><a href="#">Becoming a Partner</a></li>
                                <li class="font-weight-bold li_background"><a href="#">SUPPORT</a></li>
                                <li><a href="#">Manual Download</a></li>
                                <li><a href="#">Remote Download</a></li>
                                <li class="font-weight-bold li_background"><a href="#">CONTACT US</a></li>
                                <li><a href="#">msg & Info</a></li>
                            </div>
                        </ul>
                        <div class="m_close_button">
                            <img :src="require('@/assets/img/main/m_cloes_white.svg')"/>
                        </div>
                    </div>
                </div>
            </div>


        </header>

        <div class="subtop">
            <div class="subtopin">
                <img :src="require('@/assets/img/sub/sub_top_img.png')"/>
                <div class="subtit">SUPPORT</div>
            </div>
        </div>

        <div class="sbreadcrumb">
            <div class="bdinner">
                <span><img :src="require('@/assets/img/sub/home_icon.svg')"></span>
                <span><img :src="require('@/assets/img/sub/next_arrow.svg')"></span>
                <span class="locatit">Support</span>
            </div>
        </div>

        <div class="scontainer">


            <div class="section">
                <div class="sectionin">
                    <div class="stit">사용자 메뉴얼</div>
                    <p class="stxt">
                        어떤 도움이 필요하신가요?
                    </p>

                    <div class="m_spmenu">

                        <div class="sdropdown">
                            <button class="dropbtn">MPS 시작하기</button>
                            <div class="sdropdown-content">
                                <a href="#">MPS 시작하기</a>
                                <a href="#">거래처 등록</a>
                                <a href="#">MPS 설치</a>
                                <a href="#">모니터링 A/S</a>
                                <a href="#">모니터링 매출</a>
                                <a href="#">모바일 기능</a>
                                <a href="#">부가기능</a>
                            </div>
                        </div>

                    </div>


                    <div class="row">
                        <div class="smenu col-lg-2 col-md-2 m_smenu">
                            <div class="mlist">
                                <div class="menutit"><img :src="require('@/assets/img/sub/nexticon.svg')">
                                    MPS 시작하기
                                </div>
                                <ul>
                                    <li>회원가입</li>
                                    <li>업체 코드 관리</li>
                                    <li>직원등록</li>
                                </ul>
                            </div>

                            <div class="mlist">
                                <div class="menutit"><img :src="require('@/assets/img/sub/nexticon.svg')">
                                    거래처 등록
                                </div>
                                <ul>
                                    <li>기본정보</li>
                                    <li>CMS 정보</li>
                                    <li>계약서 등록</li>
                                </ul>
                            </div>
                            <div class="mlist">
                                <div class="menutit"><img :src="require('@/assets/img/sub/nexticon.svg')">
                                    MPS 설치
                                </div>

                            </div>

                            <div class="mlist">
                                <div class="menutit"><img :src="require('@/assets/img/sub/nexticon.svg')">
                                    모니터링 A/S
                                </div>

                            </div>

                            <div class="mlist">
                                <div class="menutit"><img :src="require('@/assets/img/sub/nexticon.svg')">
                                    모니터링 매출
                                </div>

                            </div>

                            <div class="mlist">
                                <div class="menutit"><img :src="require('@/assets/img/sub/nexticon.svg')">
                                    모바일 기능
                                </div>

                            </div>
                            <div class="mlist">
                                <div class="menutit"><img :src="require('@/assets/img/sub/nexticon.svg')">
                                    부가기능
                                </div>

                            </div>

                        </div>


                        <div class="spcont col-lg-10 col-md-10">

                            <div class="rightc">
                                <h3>MPS 시작하기</h3>
                                <div class="spdown">
                                    <a href="#"><img :src="require('@/assets/img/sub/pdf_icon.svg')">
                                        TNBOX_MPS_메뉴얼.pdf</a>
                                </div>
                                <div class="supcont">
                                    <p>1. 메인페이지 최상단 우측에 회원가입과 로그인이 배치되어 있습니다.</p>

                                    <img :src="require('@/assets/img/sub/menual01.png')">

                                </div>

                            </div>

                        </div>
                    </div>


                </div>
            </div>

        </div>

        <section>
            <div class="five_content">
                <ul>
                    <li>ABOUT
                        <ul>
                            <li>Profile</li>
                            <li>MPS Business</li>
                            <li>SI Business</li>
                        </ul>
                    </li>
                    <li>PRODUCT
                        <ul>
                            <li>TN-BOX(Web)</li>
                            <li>TN-BOX(Mobile)</li>
                        </ul>
                    </li>
                    <li>PARTNERS
                        <ul>
                            <li>Partners Programs</li>
                            <li>Becoming a Partners</li>
                        </ul>
                    </li>
                    <li>SURPPORT
                        <ul>
                            <li>Manual Download</li>
                            <li>Remote Download</li>
                        </ul>
                    </li>
                    <li>CONTACT US
                        <ul>
                            <li>Message & Info</li>
                        </ul>
                    </li>
                </ul>
            </div>
        </section>


        <footer>
            <div class="footer">

                <div class="footer_wrap">
                    <div>
                        <img :src="require('@/assets/img/main/logo_footer.svg')"/>
                    </div>

                    <div class="footer_flex row">
                        <div class="foottxt">서울특별시 강서구 마곡동 마곡중앙2로 매트로비즈타워 504호 사업자등록번호 808-87-01665
                            <span>© Copyright 2020. All Rights Reserved.</span>
                        </div>

                        <div class="familyarea col-lg-2">
                            <select name="family">
                                <option value="site">FAMILY SITE</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    </div>
</template>

<style scoped>
    /*_-------------------------------------------------------------------서브페이지 공통*/

    #header {
        width: 100%;
        margin: 0 auto;
        height: 100px;

    }

    .m_header {
        display: none;
    }

    .header_container {
        position: absolute;
        height: 100px;
        width: 100%;
        left: 0;
        z-index: 1000;
    }

    .open {
        display: none;
    }

    /*메뉴 마우스 호버시 나오는 영역입니다._______________________________ start*/
    .header_container {
        position: relative;
        background: #f9f9f9;
        height: 300px;
    }

    /*메뉴 마우스 호버시 나오는 영역입니다._______________________________ end*/
    .header_container::before {
        content: "";
        position: relative;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100px;
    }

    .header_container.open::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100px;
        background-color: #fff;
    }


    .header_container .inner {
        position: relative;
        width: 100%;
        max-width: 1200px;
        height: 100px;
        margin: 0 auto;
    }


    .header_container .logo {
        position: absolute;
        top: 28px;
        z-index: 111;
    }


    .header_menu_wrap {
        position: absolute;
        height: 100px;
        z-index: 30;
        width: 100%;
        left: 0px;
        top: 37px;
    }

    .header_menu_wrap > ul {
        display: flex;
        justify-content: space-between;
        list-style: none;
        width: 1200px;
        margin: 0 auto;
        position: relative;
        padding-left: 281px;
    }

    .header_menu_wrap > ul > li {
        width: 170px;
        display: inline-block;
        text-align: right;
        position: relative;
    }

    .header_menu_wrap > ul > li > a:hover {
        color: #0f70b7;
    }

    .sub_menu > ul > li:hover {
        text-decoration: underline;
        cursor: pointer;
    }


    .header_menu_wrap > ul > li > a {

        font-size: 1.8rem;
        letter-spacing: -0.54px;
        line-height: 27px;
        font-weight: 900;
        color: #000;
        font-family: 'Noto Sans KR', sans-serif;
    }

    .header_menu_wrap > ul > li > a:hover {
        text-decoration: none;
    }


    .sub_menu {
        /*position: absolute;
        top: 53px;
        z-index: 99;
        padding: 0;
        background-color: red;
        width: 100%;*/
        position: absolute;
        top: 63px;
        left: 50px;
        z-index: 30;
        padding-bottom: 36px;

    }

    .menu01 {
        left: 109px;
    }

    .menu02 {
        left: 71px;
    }

    .menu03 {
        left: 75px;
    }

    .menu04 {
        left: 86px;
    }

    .menu05 {
        left: 68px;
    }


    .sub_menu > ul {
        /* list-style: none;
         padding: 0;
         max-width: 1200px;
         margin: 0 auto;*/
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 0;


    }

    .sub_menu > ul > li {
        /*width: calc(25% - 36px);*/
        width: 170px;

        padding-top: 26px;
        font-size: 1.4rem;
        line-height: 20px;
        letter-spacing: 0.35px;
        color: #333;
        text-align: left;
    }


    /*pc header end*/


    .subtop {
        width: 100%;
        height: 296px;
    }

    .subtop .subtopin {
        width: 1200px;
        margin: 0 auto;


    }

    .subtop .subtopin img {
        width: 419px;
        display: inline-block;
        margin-left: 192px;
    }

    .subtop .subtopin .subtit {
        display: inline-block;
        font-size: 4.5rem;
        letter-spacing: -2.25px;
        font-weight: 900;
        color: #000000;
        vertical-align: middle;
    }

    .sbreadcrumb {
        width: 100%;
        background: #0F70B7;
        height: 62px;
    }

    .sbreadcrumb .bdinner {
        width: 1200px;
        margin: 0 auto;
    }

    .sbreadcrumb .bdinner span {
        padding-right: 20px;
        vertical-align: bottom;
        display: inline-block;
        padding-top: 19px;
    }

    .sbreadcrumb .bdinner span img {
        vertical-align: sub;
    }

    .sbreadcrumb .bdinner span.locatit {
        font-size: 1.8rem;
        color: #fff;
        font-weight: 900;
        padding-top: 13px;
        display: inline-block;
    }

    .scontainer {
        width: 100%;
    }

    .scontainer .section {
        padding: 100px 20px;
    }

    .scontainer .section .sectionin {
        width: 1200px;
        margin: 0 auto;
        overflow: hidden;
    }

    .scontainer .section .sectionin .stit {
        color: #000;
        font-size: 4.5rem;
        letter-spacing: -1.15px;
        line-height: 70px;
        font-weight: 900;
        padding-top: 20px;
        text-align: center;
    }

    .scontainer .section .sectionin .stxt {
        font-size: 1.8rem;
        color: #000;
        line-height: 18px;
        letter-spacing: -0.9px;
        text-align: center;
        padding-top: 12px;
        line-height: 27px;
    }

    .scontainer .section.bggrey {
        background-color: #FAFAFA;
    }

    /*_-------------------------------------------------------------------서브페이지 공통*/


    .scontainer .section .smenu {
        display: flex;
        flex-direction: column;
        margin-top: 80px;
    }

    .scontainer .section .smenu .mlist {
        display: block;
    }

    .scontainer .section .smenu .mlist ul li {
        list-style: none;
        font-size: 1.6rem;
        letter-spacing: -0.8px;
        color: #000;
        line-height: 24px;
        padding-bottom: 4px;
    }

    .scontainer .section .smenu .mlist .menutit {
        font-weight: 900;
        font-size: 2rem;
        letter-spacing: -1px;
        color: #000;
        padding-bottom: 16px;
    }

    .scontainer .section .smenu .mlist .menutit img {
        vertical-align: inherit;
    }

    .scontainer .section .smenu .mlist ul li {
        display: block;
    }

    .scontainer .section .spcont {
        display: flex;
        margin-top: 80px;
        padding-left: 100px;
    }

    .scontainer .section .spcont .rightc {
        display: block;
        flex: none;
        width: 100%;
    }

    .scontainer .section .spcont .rightc h3 {
        font-size: 2.4rem;
        color: #000;
        letter-spacing: -1.2px;
        font-weight: 900;
        display: block;
    }

    .scontainer .section .spcont .rightc .spdown {
        display: block;
        text-align: right;

    }

    .scontainer .section .spcont .rightc .spdown a {
        color: #404040;
        font-size: 1.3rem;
    }

    .scontainer .section .spcont .rightc .spdown a img {
        width: 18px;
        margin-right: 4px;
        vertical-align: sub;
    }

    .scontainer .section .spcont .rightc .supcont {
        margin-top: 10px;
        font-size: 1.6rem;
        color: #000;
        line-height: 24px;
        letter-spacing: -1px;
        color: #000;
    }

    .scontainer .section .spcont .rightc .supcont img {
        width: 100%;
    }

    .m_spmenu {
        display: none;
    }

    .five_content {
        width: 100%;
        height: 202px;
        background-color: #ececec;
        border-bottom: 1px solid #cccccc;
    }

    .five_content > ul {
        padding: 40px 0 0 0;
        font-weight: bold;
        color: #666;
        font-size: 1.4rem;
        width: 1200px;
        margin: 0 auto;
        list-style: none;
        display: flex;
        justify-content: space-between;
    }

    .five_content > ul > li > ul {
        width: 240px;
        list-style: none;
        padding: 16px 0 0 0;
        font-weight: normal;
        color: #9a9a9a;
    }

    .five_content > ul > li > ul > li {
        padding-bottom: 12px;
        cursor: pointer;
    }


    .footer {
        width: 100%;
        height: 100px;
        background-color: #ececec;
    }

    .footer_wrap {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: start;
        font-size: 1.3rem;
        color: #9a9a9a;
        padding: 23px 20px 0px 0px;
    }

    .footer_wrap > div {
        margin-top: -13px;
    }

    .footer_flex {
        line-height: 18px;
        width: 100%;
        padding-left: 180px;
    }

    .footer_flex span {
        display: block;
        margin-top: 4px;
        font-size: 1.3rem;
        color: #9a9a9a;
    }

    .familyarea {
        margin-left: 72px;
    }

    .familyarea select {
        margin-left: 20px;
    }

    select {
        background: transparent;
        border: 1px solid #cccccc !important;
        width: 170px !important;
        height: 30px;
        color: #9a9a9a;
    }


    /*---------------------------------------------------------------------------------------------------tablet*/
    @media screen and (max-width: 1199px) and (min-width: 750px) {

        /*모바일 헤더 메뉴 start */
        #header {
            width: 100%;
            margin: 0 auto;
            height: 50px;
        }

        .inner {
            display: flex;
            justify-content: space-between;
            line-height: 50px;
            padding: 0 16px;
        }

        .logo {
            width: 150px;
            height: 25px;
        }

        .logo > a > img {
            width: 100%;
        }

        .m_header div:nth-child(2) {
            width: 30px;
            height: 30px;
        }

        .m_header div:nth-child(2) img {
            width: 100%;
        }

        .m_header_submenu_wrap {
            position: fixed;
            top: 0;
            overflow-y: auto;
            overflow-x: hidden;
            right: 0;
            z-index: 9999;
            width: 100%;
            height: 100%;
            display: none;
        }

        .side_submenu_li_wrap li {
            list-style: none;
            padding-left: 20px;
            height: 40px;
            line-height: 40px;
            font-size: 1.6rem;
        }

        .side_submenu_li_wrap li a {
            color: #363636;
        }

        .side_submenu ul {
            width: 50%;
            height: 100%;
            background-color: white;
            position: fixed;
            right: 0;
            top: 0;
            padding: 0;
        }

        .sub_menu_background {
            position: fixed;
            width: 100%;
            height: 100%;
            background-color: #000000;
            opacity: 0.6;
        }

        .li_background {
            background-color: #EDF2F6;
        }

        .li_background li {
            padding-bottom: 5px;
        }

        .header_container {
            display: none;
        }

        .m_close_button {
            position: fixed;
            left: 45% !important;
            top: 4px !important;
        }

        .m_close_button > img {
            width: 24px !important;
            height: 24px !important;
        }

        .header_container {
            display: none;
            width: 100%;

        }

        .header_menu_wrap {
            display: none;
        }

        .m_close_button {
            position: fixed;
            left: 340px;
            top: -7px;
        }

        /*모바일 헤더 메뉴 end */

        .subtop .subtopin {
            width: 1200px;
            margin: 0 8%;
        }

        .subtop .subtopin img {
            width: 419px;
            display: inline-block;
            margin-left: 0px;
        }

        .sbreadcrumb .bdinner {
            width: 1200px;
            margin: 0 auto;
            padding-left: 20px;
        }

        .scontainer .section .sectionin {
            width: 100%;
        }

        .scontainer .section .smenu .mlist .menutit img {
            vertical-align: inherit;
            width: 16px;
        }

        .scontainer .section .smenu .mlist ul {
            padding-left: 20px;
        }

        .scontainer .section .smenu .mlist ul li {
            list-style: none;
            font-size: 1.3rem;
            letter-spacing: -0.8px;
            color: #000;
            line-height: 24px;
            padding-bottom: 4px;
        }

        .scontainer .section .smenu .mlist .menutit {
            font-weight: 900;
            font-size: 1.4rem;
            letter-spacing: -1px;
            color: #000;
            padding-bottom: 16px;
        }


        .scontainer .section .spcont {
            display: flex;
            margin-top: 80px;
            padding-left: 10px;
        }

        .m_spmenu {
            display: none;
        }

        .scontainer .section .spcont .rightc .supcont {
            margin-top: 10px;
            font-size: 1.6rem;
            color: #000;
            line-height: 24px;
            letter-spacing: -1px;
            color: #000;
        }

        .five_content {
            width: 100%;
            padding: 0 20px;
        }

        .five_content > ul {
            width: 100%;
            margin: 0 auto;
            font-size: 1.3rem;
            letter-spacing: -0.5px;
        }

        .five_content > ul > li > ul {
            width: 100%;
        }

        .footer {
            width: 100%;
            height: 130px;
            background-color: #ececec;
            padding-bottom: 20px;
        }

        .footer .footer_wrap {
            width: 100%;
            padding: 0 20px;
        }

        .footer_wrap > div {
            margin-top: 14px;
        }

        .footer_flex {
            line-height: 18px;
            width: 100%;
            padding-left: 50px;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
        }

        .footer_flex span {
            display: block;
            margin-top: 4px;
            font-size: 1.3rem;
            color: #9a9a9a;
        }

        .familyarea {
            margin-left: 0px;
            margin-top: 10px;
        }

        .familyarea select {
            margin-left: 0px;
        }


    }

    /*---------------------------------------------------------------------------------------------------mobile*/
    @media (max-width: 749px) {
        /*grid*/

        #header{
            width: 100%;
            margin: 0 auto;
            height: 50px;
        }

        .inner {
            display: flex;
            justify-content: space-between;
            line-height: 50px;
            padding: 0 16px;
        }

        .logo{
            width: 105px;
            height: 25px;
        }

        .logo > a > img {
            width: 100%;
        }

        /*모바일 헤더 메뉴 start */

        .m_header_submenu_wrap {
            position: fixed;
            top: 0;
            overflow-y: auto;
            overflow-x: hidden;
            right: 0;
            z-index: 9999;
            width: 100%;
            height: 100%;
            display: none;
        }

        .side_submenu_li_wrap li {
            list-style: none;
            padding-left: 20px;
            height: 40px;
            line-height: 40px;
        }

        .side_submenu_li_wrap li a {
            color: #363636;
        }

        .side_submenu ul {
            width: 70%;
            height: 100%;
            background-color: white;
            position: fixed;
            right: 0;
            top: 0;
            padding: 0;
            overflow-y: auto;
        }

        .sub_menu_background {
            position: fixed;
            width: 100%;
            height: 100%;
            background-color: #000000;
            opacity: 0.6;
        }

        .li_background {
            background-color: #EDF2F6;
        }

        .li_background li {
            padding-bottom: 5px;
        }

        .header_container {
            display: none;
        }

        .m_close_button {
            position: fixed;
            left: 24%;
            top: -7px;
        }

        /*모바일 헤더 메뉴 end */

        .subtop .subtopin img {
            width: 136px;
            display: inline-block;
            margin-left: 0px;
        }

        .subtop .subtopin {
            width: 1200px;
            margin: 0 13%;
        }

            .row {
                margin-left: 0px;
                margin-right: 0px;
            }

            .col-lg-6 {
                padding-right: 0px;
                padding-left: 0px;
            }

            .subtop {
                width: 100%;
                height: 97px;
                border-bottom: 1px solid #0F70B7;
            }

            .subtop .subtopin {
                width: 100%;
            }

            .subtop .subtopin img {
                width: 136px;
                display: inline-block;
                margin-left: 18px;
            }

            .subtop .subtopin .subtit {
                display: inline-block;
                font-size: 2rem;
                letter-spacing: -1px;
                font-weight: 900;
                color: #000000;
                vertical-align: middle;
            }

            .sbreadcrumb {
                display: none;
            }


            .scontainer .section {
                padding: 48px 20px;
            }

            .scontainer .section .sectionin {
                width: 100%;
                overflow: hidden;
            }

            .scontainer .section .sectionin .stit {
                text-align: center;
                font-size: 2rem;
                color: #000;
                font-weight: 900;
                letter-spacing: -1px;
                line-height: 40px;

            }

            /*menu*/
        .m_spmenu {
            width: 100%;
            display: block;
        }

        .m_spmenu .dropbtn {
            background-color: #fff;
            color: #000000;
            border: 1px solid #e1e1e1;
            border-radius: 4px;
            font-size: 1.3rem;
            letter-spacing: -0.65px;
            height: 40px;
            line-height: 40px;
            width: 100%;
            text-align: left;
            padding-left: 16px;
        }

        .sdropdown {
            position: relative;
            display: block;
            text-align: left;

        }

        .sdropdown-content {
            display: none;
            position: absolute;
            background-color: #fff;
            border: 1px solid #e1e1e1;
            box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
            z-index: 1;
            width: 100%;
        }

        .sdropdown-content a {
            color: black;
            padding: 12px 16px;
            text-decoration: none;
            display: block;
            font-size: 1.3rem;
            color: #000;


        }

        .sdropdown-content a:hover {
            background-color: #ddd;
        }

        .sdropdown:hover .sdropdown-content {
            display: block;
        }

        /*.dropdown:hover .dropbtn {background-color: #3e8e41;}*/
        /*menu*/
        .m_smenu {
            display: none !important;
        }

        .scontainer .section .spcont {
            display: block;
            margin-top: 30px;
            padding-left: 0px;
        }

        .scontainer .section .spcont .rightc h3 {
            font-size: 1.6em;
            color: #000;
            letter-spacing: -1.2px;
            font-weight: 900;
            display: block;
            margin-bottom: 15px;
        }


        .scontainer .section .spcont .rightc .supcont {
            margin-top: 10px;
            font-size: 1.3rem;
            color: #000;
            line-height: 24px;
            letter-spacing: -1px;
            color: #000;
        }

        .five_content {
            display: none;
        }

        .footer {
            width: 100%;
            height: 197px;
            padding: 0 20px;
        }

        .footer_wrap {
            flex-direction: column;
            width: 100%;
            padding-top: 0;
        }

        .footer_wrap > div {
            text-align: center;
            margin: 0 auto;
        }

        .footer_wrap > div > img {
            width: 140px;
            height: 55px;
        }

        .footer_flex {
            padding-left: 0;
            flex-direction: column-reverse;
        }

        .foottxt {
            width: 100%;
            margin: 0 auto;
            padding-top: 25px;
        }

        .familyarea {
            margin-left: 0;
            padding-top: 10px;
        }


    }

</style>

<script>
    export default {
        name: "SurpportTest"
    }
</script>